import React from 'react';
import styled from 'styled-components';

// Components
import {Center, Heading} from '@matthahn/sally-ui';
import {Page} from '../../app/Page';

// Helper Components
const Container = styled.div`
  width: 100%;
  height: 100vh;
`;

const Page404 = () => (
  <Page>
    <Container>
      <Center>
        <div>
          <Heading size="1">(╯°□°）╯︵ ┻━┻</Heading>
          <br />
          <br />
          <Heading size="3" grey>
            Move along.. Nothing to see here..
          </Heading>
        </div>
      </Center>
    </Container>
  </Page>
);

export default Page404;
