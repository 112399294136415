import React, {Component} from 'react';
import PropTypes from 'prop-types';

// Libs
import {setHistory} from '../../../libs/appUrl';

// Components
import {Page, AlertSystem, NotificationSystem} from '@matthahn/sally-ui';
import {Routes} from '../../app/Routes';

class App extends Component {
  static propTypes = {
    history: PropTypes.object,
  };

  componentDidMount() {
    setHistory(this.props.history);
  }

  render() {
    return (
      <Page>
        <Routes />
        <AlertSystem />
        <NotificationSystem />
      </Page>
    );
  }
}

export {App};

export default App;
