import React, {Component} from 'react';

// Api
import {getGoogleDoc} from '@matthahn/sally-fn/lib/document/api';

// Components
import Privacy from '../../components/Privacy/Privacy';
import Loader from '../../components/Loader/Loader';

// Libs
import {redirect} from '../../../libs/appUrl';

class PolicyContainer extends Component {
  state = {
    loading: false,
    html: '',
  };

  componentDidMount() {
    this.init();
  }

  init = async () => {
    try {
      const html = await getGoogleDoc(
        '1VU6-OSwjGg2IRXgbHy0awKfxrwZzMyLF1ZfTdMBFtmo'
      );
      console.log(html);
      this.setState({html: `${html}`});
    } catch (error) {
      redirect('/404');
    }
  };

  render() {
    const {html} = this.state;
    return !html ? <Loader /> : <Privacy html={html} />;
  }
}

export default PolicyContainer;
