import styled from 'styled-components';

// Libs
import {lib} from '@matthahn/sally-ui';

// Colors
const {
  colors: {grey},
} = lib;

const Container = styled.div`
  width: 100%;
  background: ${grey};
`;

export default Container;
